import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, IconButton, Box, FormHelperText } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Spacer from "../spacer.component";
import Text from "../text.component";

function FormFieldText({
  isAuthScreen,
  name,
  placeholder,
  type,
  width,
  disabled,
  isDisableEnter,
  onClickHandle,
  isTransparent,
  autoFocus,
  value,
}) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const showError = touched[name] && typeof errors[name] === "string";

  const getType = (inputType) => {
    const tempType = inputType;
    if (tempType === "password" && isShowPassword) {
      return "string";
    }
    return tempType;
  };

  const keyPress = (e) => {
    if (name === "expireNoDaysFromPurchaseDate" && e.key === "-") {
      e.preventDefault();
    }

    if (e.keyCode === 13 && !isDisableEnter) {
      handleSubmit();
    }
  };

  return (
    <Box>
      <TextField
        autoFocus={autoFocus}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        }}
        onClick={onClickHandle}
        onWheel={(e) => {
          if (type === "number") {
            e.target.blur(); // Prevent scrolling from changing the number
          }
        }}
        disabled={disabled}
        onKeyDown={keyPress}
        helperText={!isAuthScreen && showError ? errors[name] : null}
        variant="outlined"
        error={showError}
        onBlur={() => setFieldTouched(name)}
        name={name}
        type={getType(type || name)}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        InputProps={{
          startAdornment: name.toUpperCase().includes("URL") && (
            <InputAdornment position="start">
              <Text type="GreyColor">https://</Text>
              <Spacer position="right" />
            </InputAdornment>
          ),
          endAdornment: name.toUpperCase().includes("PASSWORD") && (
            <InputAdornment position="start">
              <IconButton
                onClick={() => {
                  setIsShowPassword(!isShowPassword);
                }}
              >
                {isShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={value || values[name]}
      />
      {isAuthScreen && showError && (
        <FormHelperText
          sx={{
            color: theme.palette.colors.text.white,
            backgroundColor: theme.palette.colors.ui.errorBox,
            padding: "10px",
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
          }}
        >
          {errors[name]}
        </FormHelperText>
      )}
    </Box>
  );
}

FormFieldText.defaultProps = {
  type: null,
  width: "100%",
  disabled: false,
  isDisableEnter: false,
  onClickHandle: null,
  isTransparent: false,
  isAuthScreen: false,
  autoFocus: false,
  value: "",
};

FormFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  isDisableEnter: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onClickHandle: PropTypes.func,
  isTransparent: PropTypes.bool,
  isAuthScreen: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormFieldText;
