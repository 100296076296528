import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { getSportCourtAvailability } from "../../../services/sport/sport.slice.services";
import SportListAutoCompleteSingleSelect from "../components/sport-list-single-select.component";

export default function SportCourtAvailabilityScreen() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const formRef = useRef();
  const [sport, setSport] = useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [resources, setResources] = useState([]);
  const [bookings, setBookings] = useState([]);
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    // Remove the license message after the component mounts
    const licenseMessage = document.querySelector(".fc-license-message");
    if (licenseMessage) {
      licenseMessage.style.display = "none"; // Hide the element
    }
  }, []);

  const handleDateChange = (newDate) => {
    const startDate = moment(newDate.start).format("YYYY-MM-DD");
    setDate(startDate);

    if (sport) {
      setIsLoading(true);
      dispatch(getSportCourtAvailability({ sportId: sport, date: startDate })).then(
        ({ meta, payload }) => {
          setIsLoading(false);
          if (meta.requestStatus === "fulfilled") {
            setResources(
              payload.data.courts.map((court) => ({
                id: court.id,
                title: court.label,
                group: court.group,
              })),
            );

            setBookings(
              payload.data.bookings.map((booking) => ({
                id: booking.id,
                resourceId: booking.court_id,
                title: booking.user.phone,
                phone: booking.user.phone,
                email: booking.user.email,
                name: booking.user.name,
                start: moment(booking.startAt).toISOString(),
                end: moment(booking.endAt).toISOString(),
              })),
            );
          }
          if (meta.requestStatus === "rejected") {
            setResources([]);
            setBookings([]);
            createSnackBar({
              message: `Failed to get sport court availability`,
              type: "error",
              open: true,
            });
          }
        },
      );
    }
  };

  const sportOnChange = (values) => {
    setSport(values.sportId?.id);
    if (values.sportId) {
      setIsLoading(true);
      dispatch(getSportCourtAvailability({ sportId: values.sportId.id, date })).then(
        ({ meta, payload }) => {
          setIsLoading(false);
          if (meta.requestStatus === "fulfilled") {
            setResources(
              payload.data.courts.map((court) => ({
                id: court.id,
                title: court.label,
                group: court.group,
              })),
            );

            setBookings(
              payload.data.bookings.map((booking) => ({
                id: booking.id,
                resourceId: booking.court_id,
                title: booking.user.phone,
                phone: booking.user.phone,
                email: booking.user.email,
                name: booking.user.name,
                start: moment(booking.startAt).toISOString(),
                end: moment(booking.endAt).toISOString(),
              })),
            );
          }
          if (meta.requestStatus === "rejected") {
            setResources([]);
            setBookings([]);
            createSnackBar({
              message: `Failed to get sport court availability`,
              type: "error",
              open: true,
            });
          }
        },
      );
    } else {
      setResources([]);
      setBookings([]);
    }
  };

  const renderEventContent = (eventInfo) => {
    const { phone, name, email } = eventInfo.event.extendedProps; // Access custom fields
    return (
      <div>
        <strong>👤 {name}</strong>
        <div>📞 {phone}</div>
        <div>✉️ {email}</div>
      </div>
    );
  };

  return (
    <Grid
      item
      sx={{
        padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <BackdropLoading isLoading={isLoading} />
      <Grid item xs={12}>
        <Text variant="screenLabel">Sport Court Availability</Text>
      </Grid>
      <Spacer size="l" />
      <Formik
        innerRef={formRef}
        initialValues={{
          sportId: null,
        }}
        onSubmit={sportOnChange}
        validationSchema={Yup.object().shape({
          sportId: Yup.mixed().nullable(),
        })}
      >
        <Grid item xs={12}>
          <Text type="RedColor">*Please select sport first</Text>
          <SportListAutoCompleteSingleSelect name="sportId" placeholder="Sport" />
        </Grid>
      </Formik>

      <Spacer size="l" />
      <FullCalendar
        plugins={[resourceTimelinePlugin]}
        initialView="resourceTimelineDay"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "resourceTimelineDay",
        }}
        resources={resources}
        events={bookings}
        eventContent={renderEventContent} // Use custom render function
        eventBackgroundColor="#138E5B"
        editable={true}
        selectable={true}
        resourceAreaHeaderContent="Courts"
        slotMinTime="00:00:00"
        slotMaxTime="24:00:00"
        slotDuration="00:30:00" // 1-hour slots
        slotMinWidth={80}
        resourceAreaWidth={200}
        allDaySlot={false}
        resourceGroupField="group" // Grouping courts by the 'group' field
        height="auto"
        datesSet={handleDateChange} // Handle date change or view change
      />
    </Grid>
  );
}
