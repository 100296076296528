import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getSportCategoryList = createAsyncThunk("merchants/sport/category/list", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/categories`,
  );
  return response;
});

export const createSport = createAsyncThunk(
  "merchants/sports/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports`,
        payload,
      );
      return response;
    } catch (err) {
      // Return the error response from the backend if available
      if (err.errors) {
        return rejectWithValue(err.errors);
      }
      throw err; // Throw other errors
    }
  },
);

export const updateSport = createAsyncThunk(
  "merchants/sports/update",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await putReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${payload.sportId}`,
        payload,
      );
      return response;
    } catch (err) {
      // Return the error response from the backend if available
      if (err.errors) {
        return rejectWithValue(err.errors);
      }
      throw err; // Throw other errors
    }
  },
);

export const uploadLayoutImage = createAsyncThunk(
  "merchants/sports/uploadLayoutImage",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${payload.sportId}/layouts`,
      payload,
    );
    return response;
  },
);

export const searchSport = createAsyncThunk("merchants/sports/full_list", async (payload) => {
  const { q, businessIds, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/?page=${page}${
      q ? "&q=".concat(q) : ""
    }${businessIds ? "&businessIds=".concat(businessIds) : ""}`,
  );
  return response;
});

export const getSportDetail = createAsyncThunk("merchants/sports/detail", async (payload) => {
  const { sportId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${sportId}`,
  );
  return response;
});

export const getSportCourts = createAsyncThunk("merchants/sports/{id}/courts", async (payload) => {
  const { sportId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${sportId}/courts`,
    payload,
  );
  return response;
});

export const getSportCourtAvailability = createAsyncThunk(
  "merchants/sports/courts/availability",
  async (payload) => {
    const { sportId, date } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${sportId}/court_availability?date=${date}`,
      payload,
    );
    return response;
  },
);

export const deleteSport = createAsyncThunk(
  "merchants/sports/delete/{sport_id}",
  async (payload) => {
    const { sportId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${sportId}`,
    );
    return response;
  },
);

export const deleteLayoutImage = createAsyncThunk(
  "merchants/sports/deleteLayoutImage",
  async (payload) => {
    const { sportId, layoutId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${sportId}/layouts/${layoutId}`,
    );
    return response;
  },
);

export const updateCourt = createAsyncThunk("merchants/sports/courts/update", async (payload) => {
  const { sportId, courtId } = payload;
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${sportId}/courts/${courtId}`,
    payload,
  );
  return response;
});

export const deleteCourt = createAsyncThunk("merchants/sports/courts/delete", async (payload) => {
  const { sportId, courtId } = payload;
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${sportId}/courts/${courtId}`,
  );
  return response;
});

export const listOffTimeSlot = createAsyncThunk(
  "merchants/sports/courts/off_time_slots/list",
  async (payload) => {
    const { sportIds, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/courts/off_time_slots?page=${page}${
        sportIds ? "&sportIds=".concat(sportIds) : ""
      }`,
    );
    return response;
  },
);

export const createOffTimeSlot = createAsyncThunk(
  "merchants/sports/courts/off_time_slots/create",
  async (payload) => {
    const { sportId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/${sportId}/courts/off_time_slots`,
      payload,
    );
    return response;
  },
);

export const deleteOffTimeSlot = createAsyncThunk(
  "merchants/sports/courts/off_time_slots/delete",
  async (payload) => {
    const { slotId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/courts/off_time_slots/${slotId}`,
    );
    return response;
  },
);

const sportSlice = createSlice({
  name: "sport",
  initialState: {
    getSportCategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createSportObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchSportObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSportObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSportDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSportCourtAvailabilityObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteSportObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadLayoutImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteLayoutImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateCourtObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteCourtObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    listOffTimeSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createOffTimeSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteOffTimeSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSportCourtsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getSportCategoryList.pending]: (state) => {
      state.getSportCategoryListObj.status = "pending";
    },
    [getSportCategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSportCategoryListObj.status = "succeeded";
      state.getSportCategoryListObj.data = data;
      state.getSportCategoryListObj.successMessage = message;
    },
    [getSportCategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSportCategoryListObj.status = "failed";
      state.getSportCategoryListObj.errorMessage = message;
    },
    [createSport.pending]: (state) => {
      state.createSportObj.status = "pending";
    },
    [createSport.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createSportObj.status = "succeeded";
      state.createSportObj.data = data;
      state.createSportObj.successMessage = message;
    },
    [createSport.rejected]: (state, action) => {
      const { message } = action.error;

      state.createSportObj.status = "failed";
      state.createSportObj.errorMessage = message;
    },
    [searchSport.pending]: (state) => {
      state.searchSportObj.status = "pending";
    },
    [searchSport.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchSportObj.status = "succeeded";
      state.searchSportObj.data = data;
      state.searchSportObj.successMessage = message;
    },
    [searchSport.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchSportObj.status = "failed";
      state.searchSportObj.errorMessage = message;
    },
    [updateSport.pending]: (state) => {
      state.updateSportObj.status = "pending";
    },
    [updateSport.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSportObj.status = "succeeded";
      state.updateSportObj.data = data;
      state.getSportDetailObj.data = data;
      state.updateSportObj.successMessage = message;
    },
    [updateSport.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSportObj.status = "failed";
      state.updateSportObj.errorMessage = message;
    },
    [getSportDetail.pending]: (state) => {
      state.getSportDetailObj.status = "pending";
    },
    [getSportDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSportDetailObj.status = "succeeded";
      state.getSportDetailObj.data = data;
      state.getSportDetailObj.successMessage = message;
    },
    [getSportDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSportDetailObj.status = "failed";
      state.getSportDetailObj.errorMessage = message;
    },
    [getSportCourtAvailability.pending]: (state) => {
      state.getSportCourtAvailabilityObj.status = "pending";
    },
    [getSportCourtAvailability.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSportCourtAvailabilityObj.status = "succeeded";
      state.getSportCourtAvailabilityObj.data = data;
      state.getSportCourtAvailabilityObj.successMessage = message;
    },
    [getSportCourtAvailability.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSportCourtAvailabilityObj.status = "failed";
      state.getSportCourtAvailabilityObj.errorMessage = message;
    },
    [deleteSport.pending]: (state) => {
      state.deleteSportObj.status = "pending";
    },
    [deleteSport.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteSportObj.status = "succeeded";
      state.deleteSportObj.data = data;
      state.deleteSportObj.successMessage = message;
    },
    [deleteSport.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteSportObj.status = "failed";
      state.deleteSportObj.errorMessage = message;
    },
    [deleteLayoutImage.pending]: (state) => {
      state.deleteLayoutImageObj.status = "pending";
    },
    [deleteLayoutImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteLayoutImageObj.status = "succeeded";
      state.deleteLayoutImageObj.data = data;
      state.getSportDetailObj.data = data;
      state.deleteLayoutImageObj.successMessage = message;
    },
    [deleteLayoutImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteLayoutImageObj.status = "failed";
      state.deleteLayoutImageObj.errorMessage = message;
    },
    [uploadLayoutImage.pending]: (state) => {
      state.uploadLayoutImageObj.status = "pending";
    },
    [uploadLayoutImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadLayoutImageObj.status = "succeeded";
      state.uploadLayoutImageObj.data = data;
      state.getSportDetailObj.data = data;
      state.uploadLayoutImageObj.successMessage = message;
    },
    [uploadLayoutImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadLayoutImageObj.status = "failed";
      state.uploadLayoutImageObj.errorMessage = message;
    },
    [updateCourt.pending]: (state) => {
      state.updateCourtObj.status = "pending";
    },
    [updateCourt.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateCourtObj.status = "succeeded";
      state.updateCourtObj.data = data;
      state.updateCourtObj.successMessage = message;
    },
    [updateCourt.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateCourtObj.status = "failed";
      state.updateCourtObj.errorMessage = message;
    },
    [deleteCourt.pending]: (state) => {
      state.deleteCourtObj.status = "pending";
    },
    [deleteCourt.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteCourtObj.status = "succeeded";
      state.deleteCourtObj.data = data;
      state.getSportDetailObj.data = data;
      state.deleteCourtObj.successMessage = message;
    },
    [deleteCourt.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteCourtObj.status = "failed";
      state.deleteCourtObj.errorMessage = message;
    },
    [listOffTimeSlot.pending]: (state) => {
      state.listOffTimeSlotObj.status = "pending";
    },
    [listOffTimeSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.listOffTimeSlotObj.status = "succeeded";
      state.listOffTimeSlotObj.data = data;
      state.listOffTimeSlotObj.successMessage = message;
    },
    [listOffTimeSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.listOffTimeSlotObj.status = "failed";
      state.listOffTimeSlotObj.errorMessage = message;
    },
    [createOffTimeSlot.pending]: (state) => {
      state.createOffTimeSlotObj.status = "pending";
    },
    [createOffTimeSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createOffTimeSlotObj.status = "succeeded";
      state.createOffTimeSlotObj.data = data;
      state.createOffTimeSlotObj.successMessage = message;
    },
    [createOffTimeSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.createOffTimeSlotObj.status = "failed";
      state.createOffTimeSlotObj.errorMessage = message;
    },
    [deleteOffTimeSlot.pending]: (state) => {
      state.deleteOffTimeSlotObj.status = "pending";
    },
    [deleteOffTimeSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteOffTimeSlotObj.status = "succeeded";
      state.deleteOffTimeSlotObj.data = data;
      state.deleteOffTimeSlotObj.successMessage = message;
    },
    [deleteOffTimeSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteOffTimeSlotObj.status = "failed";
      state.deleteOffTimeSlotObj.errorMessage = message;
    },
    [getSportCourts.pending]: (state) => {
      state.getSportCourtsObj.status = "pending";
    },
    [getSportCourts.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSportCourtsObj.status = "succeeded";
      state.getSportCourtsObj.data = data;
      state.getSportCourtsObj.successMessage = message;
    },
    [getSportCourts.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSportCourtsObj.status = "failed";
      state.getSportCourtsObj.errorMessage = message;
    },
  },
});

export default sportSlice.reducer;

// state
export const sportSelector = (state) => state.sport;
