import { Box, Button, FormHelperText, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import RedCtaButton from "../../../components/button/red-cta-button.component";
import FormCheckBox from "../../../components/forms/form-check-box.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSelect from "../../../components/forms/form-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import {
  deleteLayoutImage,
  getSportDetail,
  sportSelector,
  updateSport,
  uploadLayoutImage,
} from "../../../services/sport/sport.slice.services";
import BusinessListAutoCompleteSingleSelect from "../../business/components/business-list-autocomplete-single-select.component";
import ConfirmModal from "../components/confirm-modal.component";
import EditLayoutForm from "../components/edit-layout-form.component";
import SportCategorySingleSelect from "../components/sport-category-single-select.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const imageValidationSchema = Yup.object().shape({
  image: Yup.string().required().label("Image Path"),
});

// Validation Schema using Yup
const validationSchema = Yup.object().shape({
  business: Yup.mixed().required("Business is required"),
  category: Yup.mixed().required("Category is required"),
  label: Yup.string().required("Label is required"),
  advanceDays: Yup.number().min(1).required("Advance days are required"),
  allowHalfHourSlot: Yup.boolean().required("Allow half-hour slot is required"),
  minHour: Yup.number().min(1).required("Minimum hour is required"),
  maxHour: Yup.number().min(1).required("Maximum hour is required"),
  offStartTime: Yup.string().required("Off Hour Start time is required"),
  offEndTime: Yup.string().required("Off Hour End time is required"),
  courtSettings: Yup.array()
    .of(
      Yup.object().shape({
        group: Yup.string().required("Group is required"),
        courtQuantity: Yup.number().min(1).required("Court quantity is required"),
        dayTypes: Yup.array()
          .of(
            Yup.object().shape({
              type: Yup.string().required("Type is required"),
              startTime: Yup.string().required("Start time is required"),
              endTime: Yup.string().required("End time is required"),
              price: Yup.number().min(1).label("Price").required("Price is required"),
            }),
          )
          .min(1, "At least one day type is required"),
      }),
    )
    .min(1, "At least one court setting is required"),
});

function SportEditScreen() {
  const history = useHistory();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateSportObj, getSportDetailObj } = useSelector(sportSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const gridStyle = { display: "flex", alignItems: "center" };
  const location = useLocation();
  const sportId = new URLSearchParams(location.search).get("sportId");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getSportDetail({ sportId })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const confirmUpdate = () => {
    setShowConfirmModal(true);
  };

  const onUploadImage = (values) => {
    setIsLoading(true);
    dispatch(uploadLayoutImage(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onRemoveImage = (layoutId) => {
    if (getSportDetailObj.data.layouts.length === 1) {
      createSnackBar({
        message: "Must have at least one image",
        type: "error",
      });
      return;
    }
    setIsLoading(true);
    dispatch(deleteLayoutImage({ sportId, layoutId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const submitSportUpdate = (values, setErrors) => {
    setIsLoading(true);
    const formPayload = structuredClone(values);
    formPayload.businessId = formPayload.business.id;
    formPayload.categoryId = formPayload.category;
    formPayload.offStartTime = moment(formPayload.offStartTime.$d).format("HH:mm:00");
    formPayload.offEndTime = moment(formPayload.offEndTime.$d).format("HH:mm:00");
    formPayload.courtSettings.forEach((setting) =>
      setting.dayTypes.forEach((type) => {
        type.startTime = moment(type.startTime.$d).format("HH:mm:00");
        type.endTime = moment(type.endTime.$d).format("HH:mm:00");
        type.amountCents = type.price * 100;
      }),
    );
    dispatch(updateSport(formPayload)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setRefresh(true);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        const formikErrors = {};
        // Transform errors for Formik
        Object.keys(payload).forEach((key) => {
          const pathParts = key.split("."); // Split 'items.0.email' into ['items', '0', 'email']
          let current = formikErrors;

          pathParts.forEach((part, index) => {
            if (index === pathParts.length - 1) {
              // Final part of the path (e.g., 'email')
              if (!current[part]) {
                const [firstError] = payload[key]; // Destructure the first error message
                // Modify the message to remove 'items.0.'
                const cleanMessage = firstError.replace(/items\.\d+\./, ""); // Removes 'items.0.'
                current[part] = cleanMessage; // Assign the cleaned error message
              }
            } else {
              // Intermediate part of the path (e.g., 'items', '0')
              if (!current[part]) current[part] = Number.isNaN(part) ? {} : []; // Create array or object
              current = current[part]; // Move deeper
            }
          });
        });
        setErrors(formikErrors);
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Grid
      item
      sx={{
        padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <BackdropLoading isLoading={isLoading} />
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text variant="screenLabel">Edit Sport</Text>
          <Button
            onClick={() => history.goBack()}
            sx={{
              textTransform: "none",
              padding: "0px",
              ":hover": { backgroundColor: "transparent" },
            }}
          >
            <Text>Back</Text>
          </Button>
        </SpaceBetweenBox>
      </Grid>
      {!refresh && getSportDetailObj.status === "succeeded" && (
        <Grid item xs={12} sx={{ marginTop: "50px" }}>
          <Formik
            initialValues={{
              sportId,
              business: getSportDetailObj.data.business,
              category: getSportDetailObj.data.category.id,
              label: getSportDetailObj.data.label,
              advanceDays: getSportDetailObj.data.advanceDays,
              allowHalfHourSlot: getSportDetailObj.data.allowHalfHourSlot,
              minHour: getSportDetailObj.data.minHour,
              maxHour: getSportDetailObj.data.maxHour,
              offStartTime: dayjs(getSportDetailObj.data.offStartTime, "HH:mm"),
              offEndTime: dayjs(getSportDetailObj.data.offEndTime, "HH:mm"),
              layouts: [],
              courtSettings: getSportDetailObj.data.courtSettings.map((setting) => ({
                id: uuidv4(),
                ...setting,
                isNew: false,
                originalGroupName: setting.group,
                dayTypes: setting.dayTypes.map((type) => ({
                  id: uuidv4(),
                  ...type,
                  startTime: dayjs(type.startTime, "HH:mm"),
                  endTime: dayjs(type.endTime, "HH:mm"),
                })),
              })),
            }}
            validationSchema={validationSchema}
            onSubmit={confirmUpdate}
          >
            {({ values, errors }) => (
              <Grid container spacing={1}>
                <ConfirmModal
                  showModal={showConfirmModal}
                  setShowModal={setShowConfirmModal}
                  onSubmit={submitSportUpdate}
                />
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Business</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <BusinessListAutoCompleteSingleSelect
                    name="business"
                    placeholder="Business"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Category</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <SportCategorySingleSelect
                    name="category"
                    placeholder="Choose category type"
                    label="Category Type"
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Label</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormFieldText name="label" placeholder="Sport Label" disabled />
                </Grid>
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Advance Days</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormFieldText name="advanceDays" placeholder="Advance Days" type="number" />
                </Grid>
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Allow Half-Hour Slot</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormCheckBox name="allowHalfHourSlot" />
                </Grid>
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Off Hour Start At</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormTimePicker
                    name="offStartTime"
                    width="100%"
                    showHalfMinute
                    disablePastTime={false}
                    setEndTimeAnHourLater
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Off Hour End At</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormTimePicker
                    name="offEndTime"
                    width="100%"
                    showHalfMinute
                    disablePastTime={false}
                    setEndTimeAnHourLater
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Minimum Hour</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormFieldText name="minHour" placeholder="Minimum Hour" type="number" />
                </Grid>
                <Grid item xs={12} md={2} sx={gridStyle}>
                  <Text>Maximum Hour</Text>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormFieldText name="maxHour" placeholder="Maximum Hour" type="number" />
                </Grid>
                <Grid item container xs={12}>
                  <FieldArray name="courtSettings">
                    {({ remove, push }) => (
                      <>
                        <Grid item xs={6} sx={gridStyle}>
                          <Text sx={{ fontSize: "20px" }}>Court Settings</Text>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                          <CtaButton
                            onClickButton={() =>
                              push({
                                id: uuidv4(),
                                group: "",
                                isNew: true,
                                courtQuantity: "",
                                dayTypes: [{ type: "", startTime: "", endTime: "", price: "" }],
                              })
                            }
                          >
                            Add Court Setting
                          </CtaButton>
                        </Grid>
                        <Grid item xs={12}>
                          {values.courtSettings.map((court, index) => (
                            <Grid
                              item
                              container
                              spacing={1}
                              key={court.id}
                              sx={{
                                marginTop: "20px",
                                padding: "20px",
                                backgroundColor: theme.palette.colors.brand.secondary,
                                borderRadius: "20px",
                              }}
                            >
                              <Grid item xs={6} sx={gridStyle}>
                                <Text sx={{ fontWeight: "bold" }}>Court Setting {index + 1}</Text>
                              </Grid>
                              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                                {court.isNew && (
                                  <RedCtaButton
                                    sx={{ backgroundColor: "red" }}
                                    onClickButton={() => remove(index)}
                                  >
                                    Remove Court Setting
                                  </RedCtaButton>
                                )}
                              </Grid>
                              <Grid item xs={12} md={2} sx={gridStyle}>
                                <Text>Group Label</Text>
                              </Grid>
                              <Grid item xs={12} md={10}>
                                <FormFieldText
                                  name={`courtSettings[${index}].group`}
                                  placeholder="Rubber Court"
                                  value={court.group}
                                />
                                {errors.courtSettings && (
                                  <FormHelperText error sx={{ marginLeft: "10px" }}>
                                    {errors?.courtSettings[index]?.group}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} md={2} sx={gridStyle}>
                                <Text>Court Quantity</Text>
                              </Grid>
                              <Grid item xs={12} md={10}>
                                <FormFieldText
                                  disabled={!!court.originalGroupName}
                                  name={`courtSettings[${index}].courtQuantity`}
                                  placeholder="Court Quantity"
                                  type="number"
                                  value={court.courtQuantity}
                                />
                                {errors.courtSettings && (
                                  <FormHelperText error sx={{ marginLeft: "10px" }}>
                                    {errors?.courtSettings[index]?.courtQuantity}
                                  </FormHelperText>
                                )}
                              </Grid>

                              <FieldArray name={`courtSettings[${index}].dayTypes`}>
                                {({ remove: removeDayType, push: pushDayType }) => (
                                  <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={6} sx={gridStyle}>
                                      <Text sx={{ fontWeight: "bold" }}>Timeslot Settings</Text>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sx={{ display: "flex", justifyContent: "end" }}
                                    >
                                      <CtaButton
                                        onClickButton={() =>
                                          pushDayType({
                                            id: uuidv4(),
                                            type: "",
                                            startTime: "",
                                            endTime: "",
                                            price: "",
                                          })
                                        }
                                      >
                                        Add Day Type
                                      </CtaButton>
                                    </Grid>
                                    {court.dayTypes &&
                                      court.dayTypes.length > 0 &&
                                      court.dayTypes.map((dayType, dayIndex) => (
                                        <Grid
                                          container
                                          item
                                          xs={12}
                                          spacing={1}
                                          key={dayType.id}
                                          sx={{
                                            backgroundColor: "#F7FCF5",
                                            padding: "20px",
                                            borderRadius: "20px",
                                            marginTop: "20px",
                                          }}
                                        >
                                          <Grid item xs={6} sx={gridStyle}>
                                            <Text>Day Type {dayIndex + 1}</Text>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            sx={{ display: "flex", justifyContent: "end" }}
                                          >
                                            {dayIndex > 0 && (
                                              <RedCtaButton
                                                sx={{ backgroundColor: "red" }}
                                                onClickButton={() => removeDayType(dayIndex)}
                                              >
                                                Remove Day Type
                                              </RedCtaButton>
                                            )}
                                          </Grid>

                                          <Grid item xs={12} md={2} sx={gridStyle}>
                                            <Text>Type</Text>
                                          </Grid>
                                          <Grid item xs={12} md={10}>
                                            <FormSelect
                                              value="value"
                                              itemList={[
                                                {
                                                  id: 1,
                                                  label: "Weekday",
                                                  value: "weekday",
                                                },
                                                {
                                                  id: 2,
                                                  label: "Weekend",
                                                  value: "weekend",
                                                },
                                              ]}
                                              name={`courtSettings[${index}].dayTypes[${dayIndex}].type`}
                                              placeholder="Select Day Type"
                                              formValue={dayType.type}
                                            />
                                            {errors.courtSettings &&
                                              errors.courtSettings[index] &&
                                              errors.courtSettings[index].dayTypes &&
                                              errors.courtSettings[index].dayTypes[dayIndex] && (
                                                <FormHelperText error sx={{ marginLeft: "10px" }}>
                                                  {
                                                    errors?.courtSettings[index]?.dayTypes[dayIndex]
                                                      ?.type
                                                  }
                                                </FormHelperText>
                                              )}
                                          </Grid>

                                          <Grid item xs={12} md={2} sx={gridStyle}>
                                            <Text>Start Time</Text>
                                          </Grid>

                                          <Grid item xs={12} md={4}>
                                            <FormTimePicker
                                              name={`courtSettings[${index}].dayTypes[${dayIndex}].startTime`}
                                              width="100%"
                                              showHalfMinute
                                              disablePastTime={false}
                                              setEndTimeAnHourLater
                                              formValue={dayType.startTime}
                                            />
                                            {errors.courtSettings &&
                                              errors.courtSettings[index] &&
                                              errors.courtSettings[index].dayTypes &&
                                              errors.courtSettings[index].dayTypes[dayIndex] && (
                                                <FormHelperText error sx={{ marginLeft: "10px" }}>
                                                  {
                                                    errors?.courtSettings[index]?.dayTypes[dayIndex]
                                                      ?.startTime
                                                  }
                                                </FormHelperText>
                                              )}
                                          </Grid>

                                          <Grid item xs={12} md={2} sx={gridStyle}>
                                            <Text>End Time</Text>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <FormTimePicker
                                              showHalfMinute
                                              disablePastTime={false}
                                              name={`courtSettings[${index}].dayTypes[${dayIndex}].endTime`}
                                              width="100%"
                                              formValue={dayType.endTime}
                                            />
                                            {errors.courtSettings &&
                                              errors.courtSettings[index] &&
                                              errors.courtSettings[index].dayTypes &&
                                              errors.courtSettings[index].dayTypes[dayIndex] && (
                                                <FormHelperText error sx={{ marginLeft: "10px" }}>
                                                  {
                                                    errors?.courtSettings[index]?.dayTypes[dayIndex]
                                                      ?.endTime
                                                  }
                                                </FormHelperText>
                                              )}
                                          </Grid>

                                          <Grid item xs={12} md={2} sx={gridStyle}>
                                            <Text>Price</Text>
                                          </Grid>
                                          <Grid item xs={12} md={10}>
                                            <FormPrice
                                              placeholder="00.00"
                                              name={`courtSettings[${index}].dayTypes[${dayIndex}].price`}
                                              formValue={dayType.price}
                                            />
                                            {errors.courtSettings &&
                                              errors.courtSettings[index] &&
                                              errors.courtSettings[index].dayTypes &&
                                              errors.courtSettings[index].dayTypes[dayIndex] && (
                                                <FormHelperText error sx={{ marginLeft: "10px" }}>
                                                  {
                                                    errors?.courtSettings[index]?.dayTypes[dayIndex]
                                                      ?.amountCents
                                                  }
                                                </FormHelperText>
                                              )}
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </Grid>
                                )}
                              </FieldArray>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                </Grid>
                <Grid item xs={12}>
                  <Form
                    validationSchema={imageValidationSchema}
                    initialValues={{ image: "", sportId }}
                    onSubmit={onUploadImage}
                  >
                    <EditLayoutForm name="image" onRemoveImage={onRemoveImage} />
                  </Form>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                  <FormSubmitButton width={200} isLoading={updateSportObj.status === "pending"}>
                    <Text type="WhiteColor">Submit</Text>
                  </FormSubmitButton>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
}

export default SportEditScreen;
