import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import {
  deleteCourt,
  sportSelector,
  updateCourt,
} from "../../../services/sport/sport.slice.services";
import DialogModal from "../../../components/notification/dialog-modal.component";
import EditCourtLabel from "./edit-court-label.component";

export default function SportTableRow({
  record,
  onHandleDelete,
  setIsLoading,
  refreshCurrentList,
}) {
  const history = useHistory();
  const theme = useTheme();
  const [showDetail, setShowDetail] = useState(false);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showEditCourt, setShowEditCourt] = useState(false);
  const { deleteCourtObj } = useSelector(sportSelector);

  const removeCourt = (courtId) => {
    setIsLoading(true);
    dispatch(deleteCourt({ sportId: record.id, courtId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowConfirmDelete(false);
        setSelectedCourt(null);
        refreshCurrentList();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onUpdateCourt = (values) => {
    dispatch(updateCourt(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowEditCourt(false);
        setSelectedCourt(null);
        refreshCurrentList();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {record.label}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.business.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.category.label}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.advanceDays}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.allowHalfHourSlot ? "Yes" : "No"}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.minHour}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.maxHour}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {record.courtSettings.reduce((sum, item) => sum + item.courtQuantity, 0)}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() =>
                history.push({
                  pathname: `/sport/edit`,
                  search: `sportId=${record.id}`,
                })
              }
            >
              <EditIcon />
            </IconButton>

            <IconButton
              onClick={() => onHandleDelete(record.id)}
              disabled={record.deletable === false}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => setShowDetail(!showDetail)}>
              {showDetail ? (
                <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.text.table }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.text.table }} />
              )}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {showDetail && (
        <>
          {selectedCourt && (
            <DialogModal
              showModal={showConfirmDelete}
              setShowModal={setShowConfirmDelete}
              title="Remove Court"
              message="Are you sure you wish to remove this court?"
              disabled={deleteCourtObj.status === "pending"}
              hint={
                record.courts.length === 1
                  ? "This is the only court under the sport, by removing the court, this sport will be deleted as well"
                  : ""
              }
              buttonText="Confirm"
              onConfirm={() => removeCourt(selectedCourt.id)}
            />
          )}
          {selectedCourt && (
            <EditCourtLabel
              showModal={showEditCourt}
              setShowModal={setShowEditCourt}
              sportId={record.id}
              court={selectedCourt}
              onUpdateCourt={onUpdateCourt}
            />
          )}

          <TableRow>
            <TableCell colSpan={9}>
              <Text marginLeft="2%">Courts</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={9}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">No</TableCell>
                    <TableCell align="center">Group</TableCell>
                    <TableCell align="center">Label</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record.courts.map((court, index) => (
                    <TableRow key={court.id}>
                      <TableCell align="center">{index + 1}.</TableCell>
                      <TableCell align="center">{court.group}</TableCell>
                      <TableCell align="center">{court.label}</TableCell>
                      <TableCell sx={{ width: "100px" }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <IconButton
                            onClick={() => {
                              setSelectedCourt(court);
                              setShowEditCourt(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              setSelectedCourt(court);
                              setShowConfirmDelete(true);
                            }}
                            disabled={record.deletable === false}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

SportTableRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    category: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    layouts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        imagePath: PropTypes.string,
      }),
    ),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    created_at: PropTypes.string,
    deletable: PropTypes.bool,
    advanceDays: PropTypes.number,
    allowHalfHourSlot: PropTypes.bool,
    minHour: PropTypes.number,
    maxHour: PropTypes.number,
    courts: PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string,
        label: PropTypes.string,
        deletable: PropTypes.bool,
      }),
    ),
    courtSettings: PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string,
        courtQuantity: PropTypes.number,
        dayTypes: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            startTime: PropTypes.string,
            endTime: PropTypes.string,
            amountCents: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  refreshCurrentList: PropTypes.func.isRequired,
};
