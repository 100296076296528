import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { sportSelector } from "../../../services/sport/sport.slice.services";
import EditImageCropper from "./edit-image-cropper.component";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const UploadButtonContainer = styled(Button)(({ theme }) => ({
  minHeight: "140px",
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  border: `2px dashed ${theme.palette.colors.ui.border}`,
  color: theme.palette.colors.ui.border,
  height: "100%",
  "&:hover": {
    background: "transparent",
  },
  marginTop: "-3px",
}));

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  stroke: theme.palette.colors.ui.white,
  strokeWidth: 2,
  fontSize: theme.fonts.fontSizes.size16,
}));

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: "0px",
  top: "10px",
  right: "10px",
  backgroundColor: theme.palette.colors.brand.primary,
  width: "25px",
  height: "25px",
  ":hover": { backgroundColor: theme.palette.colors.brand.primary },
}));

function EditLayoutForm({ name, onRemoveImage }) {
  const theme = useTheme();
  const { setFieldError, errors, setFieldTouched } = useFormikContext();
  const [image, setImage] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("1150"));
  const showError = typeof errors[name] === "string";
  const { getSportDetailObj } = useSelector(sportSelector);

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    return 4;
  };

  const inputButton = () => (
    <input
      type="file"
      name={name}
      hidden
      accept="image/*"
      onClick={(event) => {
        event.target.value = null;
      }}
      onBlur={() => setFieldTouched(name)}
      onChange={(e) => {
        const { files } = e.target;
        if (files) {
          const selectedFIles = [];
          const targetFilesObject = [...files];
          targetFilesObject.map((file) => selectedFIles.push(file));
          const allowedFileType = selectedFIles.some((item) =>
            SUPPORTED_FORMATS.includes(item.type),
          );
          if (allowedFileType) {
            setImage(URL.createObjectURL(selectedFIles[0]));
            setShowCropper(true);
          } else if (!allowedFileType) {
            setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
          }
        }
      }}
    />
  );

  const renderUploadButton = () => (
    <UploadButtonContainer onClick={() => setFieldError(name, "")} component="label" fullWidth>
      <AddCircleOutlineRoundedIcon />
      {inputButton()}
    </UploadButtonContainer>
  );

  const renderImageList = () => {
    if (getSportDetailObj.status === "succeeded") {
      return (
        <Grid container spacing={1}>
          <EditImageCropper
            name={name}
            setIsShowModal={setShowCropper}
            isShowModal={showCropper}
            imageFile={image}
          />
          {getSportDetailObj.data.layouts.map((item) => (
            <Grid item xs={getItemToShow()} key={item.id}>
              <Box sx={{ position: "relative" }}>
                <StyledButtonIcon onClick={() => onRemoveImage(item.id)}>
                  <StyledCloseIcon />
                </StyledButtonIcon>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <img
                    src={item.imagePath}
                    alt="business-img"
                    style={{
                      borderTopLeftRadius: theme.shape.borderRadius[0],
                      borderTopRightRadius: theme.shape.borderRadius[0],
                      borderBottomLeftRadius: theme.shape.borderRadius[0],
                      borderBottomRightRadius: theme.shape.borderRadius[0],
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={getItemToShow()}>
            {renderUploadButton()}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="130px" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="130px" />
        </Grid>
        <Grid item xs={getItemToShow()}>
          <SkeletonLoader width="100%" height="130px" />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box marginTop="20px">
      <LabelContainer>
        <Text variant="screenLabel">Layouts</Text>
      </LabelContainer>
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      <Spacer size="m" position="top" />
      {renderImageList()}
    </Box>
  );
}

EditLayoutForm.propTypes = {
  onRemoveImage: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default EditLayoutForm;
