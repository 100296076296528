import { Box, Grid, Modal, styled, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import Form from "../../../components/forms/form.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import TopTitleBox from "../../../components/top-title-box.component";
import theme from "../../../infrastructure/theme";
import { sportSelector } from "../../../services/sport/sport.slice.services";
import SportCourtMultipleSelect from "./sport-court-multiple-select.component";
import SportListAutoCompleteSingleSelect from "./sport-list-single-select.component";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderRadius[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
  maxHeight: "700px",
  overflow: "auto",
});

const validationSchema = Yup.object().shape({
  sportId: Yup.mixed().required().label("Sport"),
});

export default function CreateCourtOffTimeSlotModal({
  showModal,
  title,
  setShowModal,
  width,
  onCreate,
}) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { createOffTimeSlotObj } = useSelector(sportSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{ sportId: null, courtIds: [], date: "", startTime: "", endTime: "" }}
              validationSchema={validationSchema}
              onSubmit={onCreate}
            >
              <Text sx={{ fontWeight: "bold" }}>Sport</Text>
              <SportListAutoCompleteSingleSelect
                name="sportId"
                placeholder="Sport"
                autoSubmit={false}
              />
              <Spacer />
              <Text sx={{ fontWeight: "bold" }}>Courts</Text>
              <SportCourtMultipleSelect name="courtIds" placeholder="Courts" />
              <Spacer />
              <Text sx={{ fontWeight: "bold" }}>Date</Text>
              <FormDatePicker name="date" width="100%" maxDays={null} disablePast />
              <Spacer />
              <Text sx={{ fontWeight: "bold" }}>Start Time</Text>
              <FormTimePicker
                name="startTime"
                width="100%"
                showHalfMinute
                disablePastTime={false}
                setEndTimeAnHourLater
              />
              <Spacer />
              <Text sx={{ fontWeight: "bold" }}>End Time</Text>
              <FormTimePicker name="endTime" width="100%" showHalfMinute disablePastTime={false} />
              <Spacer size="l" />
              <FormSubmitButton isLoading={createOffTimeSlotObj.status === "pending"}>
                <Text type="WhiteColor">Submit</Text>
              </FormSubmitButton>
            </Form>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CreateCourtOffTimeSlotModal.defaultProps = {
  width: "600px",
  title: "",
};

CreateCourtOffTimeSlotModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string,
  setShowModal: PropTypes.func.isRequired,
  width: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
};
