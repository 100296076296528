import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { sportSelector } from "../../../services/sport/sport.slice.services";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const validationSchema = Yup.object().shape({
  label: Yup.string().required().label("New Court Label"),
});

export default function EditCourtLabel({ showModal, setShowModal, sportId, court, onUpdateCourt }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm } = useFormikContext();
  const { updateCourtObj } = useSelector(sportSelector);

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "600px",
        }}
      >
        <Form
          initialValues={{
            sportId,
            courtId: court.id,
            label: "",
            originalLabel: court.label,
          }}
          validationSchema={validationSchema}
          onSubmit={onUpdateCourt}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text variant="screenLabel">Edit Court Label</Text>
            </Grid>
            <Grid item xs={12}>
              <Spacer size="m" position="top" />
              <Text>Current Court Label</Text>
              <FormFieldText name="originalLabel" label="Current Court Label" disabled />
            </Grid>
            <Grid item xs={12}>
              <Spacer size="m" position="top" />
              <Text>New Court Label</Text>
              <FormFieldText name="label" placeholder="Enter new court label" label="Court Label" />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <CtaBorderButton
                  onClickButton={() => setShowModal(false)}
                  width="100px"
                  isLoading={updateCourtObj.status === "pending"}
                >
                  <Text type="BrandColor">Cancel</Text>
                </CtaBorderButton>
                <Spacer size="m" position="left" />
                <FormSubmitButton width="100px" isLoading={updateCourtObj.status === "pending"}>
                  <Text type="WhiteColor">Save</Text>
                </FormSubmitButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </ModalBox>
    </Modal>
  );
}

EditCourtLabel.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onUpdateCourt: PropTypes.func.isRequired,
  sportId: PropTypes.string.isRequired,
  court: PropTypes.shape({
    id: PropTypes.number,
    group: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};
