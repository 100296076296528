import { configureStore } from "@reduxjs/toolkit";
import authSliceServices from "./auth/auth.slice.services";
import bookingSliceServices from "./booking/booking.slice.services";
import businessSliceServices from "./business/business.slice.services";
import calendarSliceServices from "./calendar/calendar.slice.services";
import fitnessClassSliceService from "./fitness/class.slice.services";
import fitnessSessionSliceService from "./fitness/sessions/session.slice.services";
import fitnessBookingSliceService from "./fitness/booking/booking.slice.service";
import packageSliceServices from "./package/package-slice.service";
import profileSliceServices from "./profile/profile.slice.services";
import profileStaffSliceServices from "./staff/profile/profile.slice.services";
import permissionStaffSliceServices from "./staff/permission/permission.slice.services";
import staffSliceService from "./staff/staff-slice.service";
import staffStatementSliceService from "./staff/statement/statement-slice.service";
import authStaffSliceServices from "./staff/auth/auth.slice.services";
import subscriptionSliceService from "./subscription/subscription-slice.service";
import statementSliceService from "./statement/statement-slice.service";
import hourlyTimeslotSliceService from "./hourly-timeslot/hourly-timeslot.slice.service";
import hourlyBookingSliceServices from "./hourly-booking/hourly-booking.slice.services";
import revenueSliceServices from "./revenue/revenue-slice.services";
import promoCodeSliceServices from "./promo-code/promo-code.slice.services";
import businessAccessSliceServies from "./business-access/business-access.slice.servies";
import userSliceService from "./user/user-slice.service";
import hourlyBookingUserSliceService from "./user/hourly-booking/hourly-booking-slice.service";
import fitnessClassUserSliceService from "./user/fitness/class.slice.service";
import eventUserSliceService from "./user/event/event.slice.service";
import subscriptionUserSliceService from "./user/subscription/subscription.slice.service";
import packageUserSliceService from "./user/package/package.slice.service";
import eventSliceServices from "./event/event.slice.services";
import eventSessionSliceService from "./event/session/session.slice.services";
import eventSessionCategorySliceService from "./event/session/category/category.slice.services";
import eventCategorySliceService from "./event/category/category.slice.services";
import eventImageSliceService from "./event/image/image.slice.services";
import eventBookingSliceService from "./event/booking/booking.slice.services";
import eventQuestionSliceService from "./event/question/question.slice.services";
import eventResponseSliceService from "./event/response/response.slice.services";
import footfallSliceService from "./footfall/footfall-slice.service";
import offDaySliceService from "./off-day/off-day.slice.service";
import offHourSliceService from "./off-hour/off-hour.slice.service";
import appointmentSliceService from "./appointment/appointment-slice.service";
import scheduleSliceService from "./schedule/schedule-slice.service";
import scheduleOffDateSliceService from "./schedule/off-date/off-date-slice.service";
import exclusiveReferralSliceService from "./exclusive-referral/exclusive-referral-slice.service";
import membershipSliceService from "./membership/membership-slice.service";
import membershipPlanSliceService from "./membership/plan/membership-plan-slice.service";
import creditSliceService from "./credit/credit-slice.service";
import commissionSettingSliceService from "./commission-setting/commission-setting-slice.service";
import sportSliceServices from "./sport/sport.slice.services";
import sportBookingSliceServices from "./sport/booking/booking.slice.services";

const store = configureStore({
  reducer: {
    auth: authSliceServices,
    profile: profileSliceServices,
    business: businessSliceServices,
    businessAccess: businessAccessSliceServies,
    staff: staffSliceService,
    user: userSliceService,
    hourlyBookingUser: hourlyBookingUserSliceService,
    fitnessClassUser: fitnessClassUserSliceService,
    eventUser: eventUserSliceService,
    subscriptionUser: subscriptionUserSliceService,
    packageUser: packageUserSliceService,
    authStaff: authStaffSliceServices,
    profileStaff: profileStaffSliceServices,
    permissionStaff: permissionStaffSliceServices,
    booking: bookingSliceServices,
    hourlyBooking: hourlyBookingSliceServices,
    calendar: calendarSliceServices,
    package: packageSliceServices,
    subscription: subscriptionSliceService,
    fitnessClass: fitnessClassSliceService,
    fitnessSession: fitnessSessionSliceService,
    fitnessBooking: fitnessBookingSliceService,
    event: eventSliceServices,
    eventSession: eventSessionSliceService,
    eventSessionCategory: eventSessionCategorySliceService,
    eventCategory: eventCategorySliceService,
    eventImage: eventImageSliceService,
    eventBooking: eventBookingSliceService,
    eventQuestion: eventQuestionSliceService,
    eventResponse: eventResponseSliceService,
    statement: statementSliceService,
    staffStatement: staffStatementSliceService,
    hourlyTimeslot: hourlyTimeslotSliceService,
    revenueSummary: revenueSliceServices,
    promoCode: promoCodeSliceServices,
    footfall: footfallSliceService,
    offDay: offDaySliceService,
    offHour: offHourSliceService,
    appointment: appointmentSliceService,
    schedule: scheduleSliceService,
    scheduleOffDate: scheduleOffDateSliceService,
    exclusiveReferral: exclusiveReferralSliceService,
    membership: membershipSliceService,
    membershipPlan: membershipPlanSliceService,
    credits: creditSliceService,
    commissionSetting: commissionSettingSliceService,
    sport: sportSliceServices,
    sportBooking: sportBookingSliceServices,
  },
});

export default store;
