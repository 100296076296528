import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Button, Box, useTheme } from "@mui/material";
import Loader from "react-js-loader";

function FormSubmitButton({
  children,
  width,
  disabled,
  isLoading,
  whiteBg,
  onClickButton,
  padding,
}) {
  const theme = useTheme();
  const { handleSubmit } = useFormikContext();

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          borderTopLeftRadius: theme.shape.borderRadius[1],
          borderTopRightRadius: theme.shape.borderRadius[1],
          borderBottomLeftRadius: theme.shape.borderRadius[1],
          borderBottomRightRadius: theme.shape.borderRadius[1],
          textTransform: "none",
          padding,
          border: `1px solid ${theme.palette.colors.brand.primary}`,
          ":disabled": { backgroundColor: "#7B828B" },
          "&:hover": {
            backgroundColor: whiteBg
              ? theme.palette.colors.ui.white
              : theme.palette.colors.brand.primary,
          },
        },
      }}
    >
      {isLoading ? (
        <Button
          variant="contained"
          disabled
          sx={{
            backgroundColor: whiteBg
              ? theme.palette.colors.ui.white
              : theme.palette.colors.brand.primary,
            width,
          }}
        >
          <Box
            sx={{ height: "25px", justifyContent: "center", display: "flex", alignItems: "center" }}
          >
            <Loader
              type="bubble-loop"
              bgColor={
                whiteBg ? theme.palette.colors.brand.primary : theme.palette.colors.brand.white
              }
              size={30}
            />
          </Box>
        </Button>
      ) : (
        <Button
          variant="contained"
          disabled={disabled}
          onClick={() => {
            if (onClickButton !== null) {
              onClickButton();
            }
            handleSubmit();
          }}
          sx={{
            backgroundColor: whiteBg
              ? theme.palette.colors.ui.white
              : theme.palette.colors.brand.primary,
            width,
            boxShadow: 0,
          }}
        >
          {children}
        </Button>
      )}
    </Box>
  );
}

FormSubmitButton.defaultProps = {
  width: "100%",
  disabled: false,
  isLoading: false,
  whiteBg: false,
  onClickButton: null,
  padding: "10px",
};

FormSubmitButton.propTypes = {
  whiteBg: PropTypes.bool,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClickButton: PropTypes.func,
  padding: PropTypes.string,
};

export default FormSubmitButton;
