import React from "react";
import PropTypes from "prop-types";
import { Button, Box, useTheme } from "@mui/material";
import Loader from "react-js-loader";

function CtaButton({
  children,
  width,
  disabled,
  isLoading,
  onClickButton,
  padding,
  whiteBg,
  startIcon,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          borderTopLeftRadius: theme.shape.borderRadius[1],
          borderTopRightRadius: theme.shape.borderRadius[1],
          borderBottomLeftRadius: theme.shape.borderRadius[1],
          borderBottomRightRadius: theme.shape.borderRadius[1],
          textTransform: "none",
          padding,
          border: `1px solid ${theme.palette.colors.brand.primary}`,
          ":disabled": { backgroundColor: "#7B828B" },
          "&:hover": {
            backgroundColor: whiteBg
              ? theme.palette.colors.ui.white
              : theme.palette.colors.brand.primary,
          },
        },
      }}
    >
      {isLoading ? (
        <Button
          startIcon={startIcon}
          variant="contained"
          disabled
          sx={{
            ":disabled": { backgroundColor: "transparent" },

            width,
          }}
        >
          <Box
            sx={{ height: "23px", justifyContent: "center", display: "flex", alignItems: "center" }}
          >
            <Loader type="bubble-loop" bgColor={theme.palette.colors.ui.white} size={30} />
          </Box>
        </Button>
      ) : (
        <Button
          variant="contained"
          startIcon={startIcon}
          disabled={disabled || isLoading}
          onClick={onClickButton}
          sx={{
            backgroundColor: whiteBg
              ? theme.palette.colors.ui.white
              : theme.palette.colors.brand.primary,
            width,
            boxShadow: 0,
          }}
        >
          {children}
        </Button>
      )}
    </Box>
  );
}

CtaButton.defaultProps = {
  width: "100%",
  disabled: false,
  startIcon: null,
  isLoading: false,
  padding: "10px",
  whiteBg: false,
};

CtaButton.propTypes = {
  startIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClickButton: PropTypes.func.isRequired,
  padding: PropTypes.string,
  whiteBg: PropTypes.bool,
};

export default CtaButton;
