import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useHistory } from "react-router-dom";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function SubscriptionTableRow({ subscriptionPlan, onHandleDelete }) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const history = useHistory();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleOpenDetail = () => {
    setShowDetail(!showDetail);
  };

  const onToggleDelete = () => {
    onHandleDelete(subscriptionPlan.id);
    handleCloseMenu();
  };

  const onHandleEdit = () => {
    history.push({
      pathname: routes.SUBSCRIPTION_EDIT,
      search: `${subscriptionPlan.id}`,
      state: subscriptionPlan,
    });
    handleCloseMenu();
  };

  const getStatusColor = (status) => {
    if (status === "inactive") {
      return "RedColor";
    }
    if (status === "pending") {
      return "YellowColor";
    }
    return "BrandColor";
  };

  return (
    <>
      <TableRow
        key={subscriptionPlan.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriptionPlan.title}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM${subscriptionPlan.price}`}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriptionPlan.membershipPrice && subscriptionPlan.membershipPrice !== null
              ? `RM${subscriptionPlan.membershipPrice}`
              : "-"}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriptionPlan.numberOfDays}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriptionPlan.expireNoDaysFromPurchaseDate
              ? subscriptionPlan.expireNoDaysFromPurchaseDate
              : "-"}
          </Text>
        </TableCell>
        <TableCell>
          <img src={subscriptionPlan.imagePath} alt="package" width={70} />
        </TableCell>

        <TableCell sx={{ width: "350px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {subscriptionPlan.shortDescription}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
            type={getStatusColor(subscriptionPlan.status)}
          >
            {subscriptionPlan.status}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon sx={{ color: theme.palette.colors.text.table }} />
            </IconButton>
            <StyledMenu
              sx={{ mt: "20px" }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem key={1} onClick={onHandleEdit}>
                <Text textAlign="center">Edit</Text>
              </MenuItem>

              {subscriptionPlan.deletable && (
                <MenuItem key={2} onClick={onToggleDelete}>
                  <Text textAlign="center">Remove</Text>
                </MenuItem>
              )}
            </StyledMenu>
            <IconButton onClick={handleOpenDetail}>
              {showDetail ? (
                <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.text.table }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.text.table }} />
              )}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {showDetail && (
        <>
          <TableRow>
            <TableCell colSpan={9}>
              <Text marginLeft="2%">Description</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={9}>
              <Text type="TableText" marginLeft="2%">
                {subscriptionPlan.description}
              </Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={9}>
              <Text marginLeft="2%">Businesses Name</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={9}>
              {subscriptionPlan.businesses.map((business) => (
                <Box key={business.name}>
                  <Text type="TableText" marginLeft="2%">
                    {business.name}
                  </Text>
                  <Spacer />
                </Box>
              ))}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

SubscriptionTableRow.propTypes = {
  onHandleDelete: PropTypes.func.isRequired,
  subscriptionPlan: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    numberOfDays: PropTypes.number,
    expireNoDaysFromPurchaseDate: PropTypes.number,
    price: PropTypes.string,
    membershipPrice: PropTypes.string,
    imagePath: PropTypes.string,
    status: PropTypes.string,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    deletable: PropTypes.bool,
  }).isRequired,
};
