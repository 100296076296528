import { useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Text from "../../../components/text.component";
import { getSportCategoryList } from "../../../services/sport/sport.slice.services";

export default function SportCategorySingleSelect({
  name,
  placeholder,
  width,
  isTransparent,
  disabled,
}) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext();
  const theme = useTheme();
  const [itemList, setItemList] = useState([]);
  const showError = touched[name] && typeof errors[name] === "string";
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
    setFieldValue("label", itemList.find((item) => item.id === event.target.value)?.label);
  };

  useEffect(() => {
    dispatch(getSportCategoryList()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setItemList(payload.data);
      }
    });
  }, []);

  return (
    <FormControl
      sx={{
        width,
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      }}
    >
      <Select
        onBlur={() => setFieldTouched(name)}
        disabled={disabled}
        value={values[name] === null ? "" : values[name]}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
        error={showError}
        displayEmpty
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        }}
      >
        <MenuItem value="" disabled>
          <Text type="GreyColor">{placeholder}</Text>
        </MenuItem>
        {itemList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

SportCategorySingleSelect.defaultProps = {
  width: "100%",
  isTransparent: false,
  disabled: false,
};

SportCategorySingleSelect.propTypes = {
  width: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
};
