import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ImageMultipleCropper from "../../../components/forms/image-multiple-cropper.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const UploadButtonContainer = styled(Button)(({ theme }) => ({
  minHeight: "70px",
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  border: `2px dashed ${theme.palette.colors.ui.border}`,
  color: theme.palette.colors.ui.border,
  height: "100%",
  "&:hover": {
    background: "transparent",
  },
  marginTop: "-3px",
}));

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  stroke: theme.palette.colors.ui.white,
  strokeWidth: 2,
  fontSize: theme.fonts.fontSizes.size16,
}));

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: "0px",
  top: "10px",
  right: "10px",
  backgroundColor: theme.palette.colors.brand.primary,
  width: "25px",
  height: "25px",
  ":hover": { backgroundColor: theme.palette.colors.brand.primary },
}));

function CreateSportLayoutList({ name }) {
  const theme = useTheme();
  const { setFieldError, values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();
  const filePreview = values[name];
  const [image, setImage] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const showError = touched[name] && typeof errors[name] === "string";

  const removeImage = (imageId) => {
    const tempDatadataToReturn = values[name].filter((item) => item.id !== imageId);
    setFieldValue(name, tempDatadataToReturn);
  };

  const inputButton = () => (
    <input
      type="file"
      name={name}
      hidden
      accept="image/*"
      onClick={(event) => {
        event.target.value = null;
      }}
      onBlur={() => setFieldTouched(name)}
      onChange={(e) => {
        const { files } = e.target;
        if (files) {
          const selectedFIles = [];
          const targetFilesObject = [...files];
          targetFilesObject.map((file) => selectedFIles.push(file));
          const allowedFileType = selectedFIles.some((item) =>
            SUPPORTED_FORMATS.includes(item.type),
          );
          if (allowedFileType && filePreview.length + selectedFIles.length <= 5) {
            setImage(URL.createObjectURL(selectedFIles[0]));
            setShowCropper(true);
          } else if (!allowedFileType) {
            setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
          }
        }
      }}
    />
  );

  const renderUploadButton = () => (
    <UploadButtonContainer onClick={() => setFieldError(name, "")} component="label" fullWidth>
      <AddCircleOutlineRoundedIcon />
      {inputButton()}
    </UploadButtonContainer>
  );

  return (
    <Box>
      <LabelContainer>
        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Upload Layouts</Text>
      </LabelContainer>
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      <Spacer size="s" position="top" />
      <Grid container spacing={1}>
        <ImageMultipleCropper
          name={name}
          setIsShowModal={setShowCropper}
          isShowModal={showCropper}
          imageFile={image}
        />
        {values[name].map((item) => (
          <Grid item xs={isMobile ? 12 : 4} key={item.id}>
            <Box sx={{ position: "relative" }}>
              <StyledButtonIcon onClick={() => removeImage(item.id)}>
                <StyledCloseIcon />
              </StyledButtonIcon>
              <img
                src={item.image}
                alt="business-img"
                style={{
                  borderTopLeftRadius: theme.shape.borderRadius[0],
                  borderTopRightRadius: theme.shape.borderRadius[0],
                  borderBottomLeftRadius: theme.shape.borderRadius[0],
                  borderBottomRightRadius: theme.shape.borderRadius[0],
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        ))}
        {values[name].length < 5 && (
          <Grid item xs={isMobile || values[name].length <= 0 ? 12 : 4}>
            {renderUploadButton()}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

CreateSportLayoutList.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CreateSportLayoutList;
