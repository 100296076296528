import "react-image-crop/dist/ReactCrop.css";
import React, { useEffect, useState } from "react";
import { Box, Modal, styled } from "@mui/material";
import ReactCrop from "react-image-crop";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";
import CtaButton from "../button/cta-button.component";
import Text from "../text.component";
import CtaBorderButton from "../button/cta-border-button.component";
import Spacer from "../spacer.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "20px",
  paddingRight: "20px",
  maxWidth: "600px",
  outline: "none",
  display: "flex",
  maxHeight: "80%",
  flexDirection: "column",
}));

const ImageCropperContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "80%",
  flex: 1,
  overflow: "scroll",
});

const BottomButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  height: "20%",
  alignItems: "center",
});

function ImageMultipleCropper({ isShowModal, setIsShowModal, name, imageFile }) {
  const [image, setImage] = useState(null);
  const cropSetting = { unit: "px", aspect: 16 / 9, width: 100, height: 56.25, x: 0, y: 0 };
  const [crop, setCrop] = useState(cropSetting);

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setCrop(cropSetting);
  }, [imageFile]);

  const handleCancel = () => {
    setCrop(cropSetting);
    setIsShowModal(false);
  };

  const getCroppedImage = () => {
    const canvas = document.createElement("canvas");
    const scaleRatio = (image.naturalWidth / image.naturalHeight) * 2;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * scaleRatio;
    canvas.height = crop.height * scaleRatio;
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleRatio,
      crop.height * scaleRatio,
    );
    const base64ImageURL = canvas.toDataURL("image/webp");
    setFieldValue(values[name].push({ id: uuidv4(), image: base64ImageURL }));
    setIsShowModal(false);
  };

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox>
        <ImageCropperContainer>
          <ReactCrop
            crop={crop}
            onChange={setCrop}
            aspect={16 / 9}
            keepSelection={true}
            style={{ height: "100%", overflow: "scroll" }}
          >
            <img
              src={imageFile}
              alt="test"
              onLoad={(o) => {
                setImage(o.target);
              }}
            />
          </ReactCrop>
        </ImageCropperContainer>

        <Spacer size="l" position="top" />
        <BottomButtonWrapper>
          <Box sx={{ width: "45%" }}>
            <CtaButton onClickButton={getCroppedImage}>
              <Text type="WhiteColor">Upload</Text>
            </CtaButton>
          </Box>
          <Box sx={{ width: "45%" }}>
            <CtaBorderButton onClickButton={handleCancel}>
              <Text type="BrandColor">Cancel</Text>
            </CtaBorderButton>
          </Box>
        </BottomButtonWrapper>
      </ModalBox>
    </Modal>
  );
}

ImageMultipleCropper.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  imageFile: PropTypes.string.isRequired,
};

export default ImageMultipleCropper;
