import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api-services";

export const getSportBookingList = createAsyncThunk(
  "merchants/sports/booking/index",
  async (payload) => {
    const { q, statuses, businessIds, categoryIds, sportIds, userIds, startAt, endAt, page } =
      payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/bookings?${
        page ? "page=".concat(page) : "page=1"
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}${q ? "&q=".concat(q) : ""}${
        categoryIds ? "&categoryIds=".concat(categoryIds) : ""
      }${sportIds ? "&sportIds=".concat(sportIds) : ""}${
        userIds ? "&userIds=".concat(userIds) : ""
      }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}${
        statuses ? "&statuses=".concat(statuses) : ""
      }`,
    );
    return response;
  },
);

export const getSportBookingCSV = createAsyncThunk(
  "merchants/sports/booking/csv",
  async (payload) => {
    const { statuses, businessIds, categoryIds, sportIds, userIds, startAt, endAt, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/sports/bookings/csv?${
        page ? "page=".concat(page) : "page=1"
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}${
        categoryIds ? "&categoryIds=".concat(categoryIds) : ""
      }${sportIds ? "&sportIds=".concat(sportIds) : ""}${
        userIds ? "&userIds=".concat(userIds) : ""
      }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}${
        statuses ? "&statuses=".concat(statuses) : ""
      }`,
    );
    return response;
  },
);

const sportBookingSlice = createSlice({
  name: "sportBooking",
  initialState: {
    getSportBookingListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSportBookingCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getSportBookingList.pending]: (state) => {
      state.getSportBookingListObj.status = "pending";
    },
    [getSportBookingList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSportBookingListObj.status = "succeeded";
      state.getSportBookingListObj.data = data;
      state.getSportBookingListObj.successMessage = message;
    },
    [getSportBookingList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSportBookingListObj.status = "failed";
      state.getSportBookingListObj.errorMessage = message;
    },
    [getSportBookingCSV.pending]: (state) => {
      state.getSportBookingCSVObj.status = "pending";
    },
    [getSportBookingCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSportBookingCSVObj.status = "succeeded";
      state.getSportBookingCSVObj.data = data;
      state.getSportBookingCSVObj.successMessage = message;
    },
    [getSportBookingCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSportBookingCSVObj.status = "failed";
      state.getSportBookingCSVObj.errorMessage = message;
    },
  },
});

export default sportBookingSlice.reducer;

// state
export const sportBookingSelector = (state) => state.sportBooking;
