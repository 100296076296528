import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function SportCourtOffTimeSlotRow({ record, onHandleDelete }) {
  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {record.sport.label}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {record.court.label}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {record.startAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {record.endAt}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={() => onHandleDelete(record.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

SportCourtOffTimeSlotRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    court: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      group: PropTypes.string,
    }),
    sport: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  }).isRequired,
  onHandleDelete: PropTypes.func.isRequired,
};
