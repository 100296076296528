import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  createOffTimeSlot,
  deleteOffTimeSlot,
  listOffTimeSlot,
  sportSelector,
} from "../../../services/sport/sport.slice.services";
import CreateCourtOffTimeSlotModal from "../components/create-court-off-time-slot-modal.component";
import SportCourtOffTimeSlotRow from "../components/sport-court-off-time-slot-row.component";
import SportListAutoComplete from "../components/sport-list-autocomplete.component";
import SportCourtOffTimeSlotTableRowLoader from "../loader/sport-court-off-time-slot-table-row-loader.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  sportIds: Yup.array().label("Sports").nullable(),
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function SportOffTimeSlotScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const formRef = useRef();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const [sports, setSports] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { listOffTimeSlotObj } = useSelector(sportSelector);
  const [selectedSlotId, setSelectedSlotId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { deleteOffTimeSlotObj } = useSelector(sportSelector);
  const [showAddModal, setShowAddModal] = useState(false);
  const columnMapping = {
    Name: "user.name",
    Phone: "user.phone",
    Status: "status",
    "Start At": "startAt",
    "End At": "endAt",
    Sport: "court.sport.label",
    "Court Type": "court.group",
    Court: "court.label",
    Price: "price",
    Business: "court.sport.business.name",
  };

  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshList = (sportIds, newPage) => {
    setSports(sportIds);
    setPage(newPage);

    dispatch(
      listOffTimeSlot({
        sportIds,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const handleCreateOffTimeSlot = (values) => {
    const formpPayload = { ...values };
    formpPayload.sportId = values.selectedSportId;
    formpPayload.date = moment(values.date).format("Y-MM-DD");
    formpPayload.startTime = moment(values.startTime.$d).format("HH:mm:00");
    formpPayload.endTime = moment(values.endTime.$d).format("HH:mm:00");

    dispatch(createOffTimeSlot(formpPayload)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowAddModal(false);
        onRefreshList([], 1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const submitFilter = (values) => {
    const sportIds = values.sportIds.map((item) => item.id);
    onRefreshList(sportIds, 1);
  };

  const clearFilter = () => {
    formRef.current.setFieldValue("sportIds", []);
  };

  const onPageChange = (e, newPage) => {
    onRefreshList(sports, newPage);
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (listOffTimeSlotObj.data && listOffTimeSlotObj.status === "succeeded") {
      records = { ...listOffTimeSlotObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const onHandleDelete = (sportId) => {
    setSelectedSlotId(sportId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteOffTimeSlot({ slotId: selectedSlotId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshList([], 1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderTableBody = () => {
    if (listOffTimeSlotObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (listOffTimeSlotObj.data?.items?.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={9}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records.items.map((item) => (
        <SportCourtOffTimeSlotRow record={item} key={item.id} onHandleDelete={onHandleDelete} />
      ));
    }
    return <SportCourtOffTimeSlotTableRowLoader />;
  };

  useEffect(() => {
    onRefreshList(sports, 1);
  }, []);

  return (
    <>
      <BackdropLoading isLoading={isLoading} />
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Off Time Slot"
        label="Are you sure you wish to remove this off time slot?"
        isLoading={deleteOffTimeSlotObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <CreateCourtOffTimeSlotModal
        title="Create Off Time Slot"
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        onCreate={handleCreateOffTimeSlot}
      />

      <Formik
        innerRef={formRef}
        initialValues={{
          sportIds: [],
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
            paddingY: theme.dimensions.ScreenPaddingY,
          }}
        >
          <Grid item container justifyContent="space-between">
            <Text variant="screenLabel">Sport Court Off Time Slots</Text>
            <Grid
              container
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "end" }}
              columnSpacing={1}
              rowSpacing={1}
            >
              <Grid item>
                <CtaButton onClickButton={() => setShowAddModal(true)}>
                  <Text type="WhiteColor">Create Off Time Slot</Text>
                </CtaButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
            <WhiteBgCardContainer>
              <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Sports</Text>
                          </Grid>

                          <Grid item xs={9}>
                            <SportListAutoComplete name="sportIds" placeholder="Sports" />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={2}>
                        <CtaButton whiteBg onClickButton={clearFilter}>
                          <Text type="BrandColor">Clear All</Text>
                        </CtaButton>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormSubmitButton>
                          <Text type="WhiteColor">Search</Text>
                        </FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>

              <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                <Grid item xs={12}>
                  <TableWrapper>
                    <Table>
                      <TableHeader
                        headerCells={["Sport", "Court", "Start At", "End At", "Action"]}
                        sortColumn={sortColumn}
                        sortOrder={sortOrder}
                        onSortChange={onSortChange}
                        columnMapping={columnMapping}
                      />
                      <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                  </TableWrapper>
                </Grid>

                <Grid item xs={12}>
                  <CustomFooter>
                    <Pagination
                      sx={{
                        "&& .Mui-selected": {
                          backgroundColor: theme.palette.colors.brand.primary,
                          color: theme.palette.colors.text.white,
                        },
                      }}
                      page={page}
                      shape="rounded"
                      onChange={onPageChange}
                      count={listOffTimeSlotObj?.data?.pagination.totalPages || 1}
                      variant="outlined"
                    />
                  </CustomFooter>
                </Grid>
              </Grid>
            </WhiteBgCardContainer>
          </Grid>
        </Grid>
      </Formik>
    </>
  );
}
