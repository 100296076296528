import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import Text from "../../../components/text.component";

export default function BookingTableRow({ booking }) {
  return (
    <>
      <TableRow
        key={booking.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.user.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.user.phone}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {booking.status.replaceAll("_", " ")}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.startAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.endAt}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.sport.label}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.bookingCourtGroups}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.bookingCourtLabels}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM ${booking.price}`}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {booking.sport.business.name}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

BookingTableRow.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    price: PropTypes.string,
    payment: PropTypes.shape({
      method: PropTypes.string,
    }),
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    sport: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      category: PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      }),
      business: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }),
    bookingCourtGroups: PropTypes.string,
    bookingCourtLabels: PropTypes.string,
    courts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        group: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
};
