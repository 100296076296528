import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CtaButton from "../../../components/button/cta-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { sportSelector } from "../../../services/sport/sport.slice.services";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.ui.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

export default function ConfirmModal({ showModal, setShowModal, onSubmit }) {
  const { values, setErrors, resetForm } = useFormikContext();
  const theme = useTheme();
  const { createSportObj } = useSelector(sportSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    setShowModal(false);
  };

  const groupDayTypes = (dayTypes) => {
    const result = {};

    dayTypes.forEach((type) => {
      if (result[type.type]) {
        result[type.type].push(type);
      } else {
        result[type.type] = [type];
      }
    });

    // Convert the object into an array if needed
    const groupedArray = Object.keys(result).map((key) => ({
      type: key,
      items: result[key],
    }));

    return groupedArray;
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ padding: isMobile ? "30px" : "40px", width: isMobile ? "350px" : "450px" }}>
        <Grid container>
          <Grid container justifyContent="flex-end">
            <CloseIconButton onClick={handleCancel}>
              <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
            </CloseIconButton>
          </Grid>
          <Grid item xs={12} paddingTop="20px" paddingBottom="10px">
            <Text variant="h5" textAlign="center">
              Court Setting Confirmation
            </Text>
          </Grid>
          <Grid item xs={12}>
            {values.courtSettings.map((setting) => (
              <Grid item key={uuidv4()}>
                <Spacer />
                <Text type="BlueColor" sx={{ fontWeight: "bold" }}>
                  {setting.group}
                </Text>
                <Divider />
                <Spacer />
                {groupDayTypes(setting.dayTypes).map((type) => (
                  <Grid item key={uuidv4()}>
                    <Spacer />
                    <Text>{type.type === "weekday" ? "Monday - Friday" : "Saturday - Sunday"}</Text>
                    {type.items.map((row) => (
                      <Text key={uuidv4()}>
                        {moment(row.startTime.$d).format("hh:mm A")} -{" "}
                        {moment(row.endTime.$d).format("hh:mm A")} : RM {row.price} per hour
                      </Text>
                    ))}
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} paddingTop="100px" sx={{ textAlign: "center" }}>
            <CtaButton
              width={200}
              onClickButton={() => {
                handleCancel();
                onSubmit(values, setErrors, resetForm);
              }}
              isLoading={createSportObj.status === "pending"}
            >
              <Text type="WhiteColor">Submit</Text>
            </CtaButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
